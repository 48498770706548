<template>
  <v-container>
    <v-row>
      <v-col>
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0"
          large
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            Locaties

            <v-spacer />

            <v-text-field
              v-model="filter"
              append-icon="mdi-magnify"
              label="Zoeken"
              single-line
              hide-details
            />

            <v-spacer />

            <v-btn
              v-if="canAddAndDeleteLocation"
              :to="{ name: 'locationsNew' }"
              color="primary"
            >
              Voeg toe
            </v-btn>
          </v-card-title>

          <v-data-table
            :headers="headers"
            :items="filteredLocations"
            :items-per-page="20"
            :footer-props="{ itemsPerPageOptions: [10, 20, 50, 200] }"
            :loading="loading"
            must-sort
            :sort-by.sync="tableSortBy"
            :sort-desc.sync="tableSortDesc"
            :custom-sort="customSorter"
          >
            <template #[`item.organization.title`]="{ item }">
              <router-link
                v-if="item.organization"
                :to="{ name: 'organizationsShow', params: { id: item.organization._id }}"
              >
                {{ item.organization.title }}
              </router-link>
            </template>

            <template #[`item.title`]="{ item }">
              <router-link
                v-if="item.title"
                :to="{ name: 'locationsShow', params: { id: item._id }}"
              >
                {{ item.title }}
              </router-link>
            </template>

            <template #[`item.wolkCounts`]="{ item }">
              <span
                v-for="(value, key) in getWolkCounts(item._id)"
                :key="key"
              >
                <b>{{ key }}</b>: {{ value }}
                <span class="mx-2">
                  |
                </span>
              </span>

              <span>
                <b>Totaal</b>: {{ getTotalWolkCount(item._id) }}
              </span>
            </template>

            <template #[`item.actions`]="{ item }">
              <wolk-delete-button
                v-if="canAddAndDeleteLocation"
                question="Are you sure?"
                @confirmed="deleteLocation(item)"
              />
            </template>

            <template
              v-if="showWolkCountFooters"
              #[`body.append`]="{ headers: tableHeaders }"
            >
              <tr>
                <td />

                <td colspan="1">
                  Geen
                </td>

                <td :colspan="tableHeaders.length - 2">
                  <span
                    v-for="(value, key) in unassignedCounts"
                    :key="key"
                  >
                    <b>{{ key }}</b>: {{ value }}
                    <span class="mx-2">
                      |
                    </span>
                  </span>

                  <span>
                    <b>Totaal</b>: {{ unassignedTotalCount }}
                  </span>
                </td>
              </tr>

              <tr>
                <td />

                <td>
                  Totaal
                </td>

                <td :colspan="tableHeaders.length - 2">
                  <span
                    v-for="(value, key) in sizeCounts"
                    :key="key"
                  >
                    <b>{{ key }}</b>: {{ value }}
                    <span class="mx-2">
                      |
                    </span>
                  </span>

                  <span>
                    <b>Totaal</b>: {{ totalAllSizedCount }}
                  </span>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios';

import { createBreadcrumbs } from '../helpers/humanizer';

import WolkDeleteButton from '../components/WolkDeleteButton.vue';

export default {
  name: 'WolkLocationsPage',
  components: {
    WolkDeleteButton,
  },
  data() {
    return {
      filter: '',
      loading: false,
      locations: [],
      wolkCounts: {},
      sizeCounts: null,
      organizationsLoaded: false,
      tableSortBy: 'title',
      tableSortDesc: false,
      viewOnly: true,
    };
  },
  computed: {
    canAddAndDeleteLocation() {
      if (this.viewOnly) {
        return false;
      }

      return this.$store.getters['auth/isAdmin'] || this.$store.getters['auth/isAdminUser'];
    },
    breadcrumbs() {
      return createBreadcrumbs(
        this.$store.state.app.dashboardHomeName,
        [
          {
            text: 'Locaties',
            disabled: true,
            exact: true,
            to: { name: 'locations' },
          },
        ],
        false,
      );
    },
    headers() {
      if (!this.organizationsLoaded) {
        return [{ text: 'Naam', value: 'title' }];
      }

      const headers = [];

      if (this.$store.getters['auth/isAdmin']) {
        headers.push({ text: 'Organisatie', value: 'organization.title' });
      }

      headers.push({ text: 'Naam', value: 'title' });

      if (this.showWolkCounts) {
        headers.push({ text: 'Wolks', value: 'wolkCounts', sortable: false });
      }

      headers.push({ text: 'Actions', value: 'actions', sortable: false });

      return headers;
    },
    showWolkCounts() {
      return this.$store.getters['auth/isUser'];
    },
    showWolkUnassignedCountFooters() {
      if (!this.showWolkCountFooters) {
        return false;
      }

      if (
        this.$store.state.myAccount.type === 'user'
          && !this.$store.state.myAccount.userMetaData.isAdmin
      ) {
        return false;
      }

      return true;
    },
    showWolkCountFooters() {
      if (!this.showWolkCounts || !this.sizeCounts) {
        return false;
      }

      if (
        this.$store.state.myAccount.type === 'user'
          && !this.$store.state.myAccount.userMetaData.isAdmin
      ) {
        return false;
      }

      return true;
    },
    unassignedCounts() {
      if (!this.sizeCounts) {
        return null;
      }

      return Object
        .values(this.wolkCounts)
        .reduce(
          (p, c) => ({
            S: p.S - c.S,
            M: p.M - c.M,
            L: p.L - c.L,
            XL: p.XL - c.XL,
          }),
          { ...this.sizeCounts },
        );
    },
    unassignedTotalCount() {
      if (!this.sizeCounts) {
        return 0;
      }

      return Object
        .values(this.wolkCounts)
        .reduce(
          (p, c) => p - c.S - c.M - c.L - c.XL,
          this.sizeCounts.S + this.sizeCounts.M + this.sizeCounts.L + this.sizeCounts.XL,
        );
    },
    totalAllSizedCount() {
      if (!this.sizeCounts) {
        return 0;
      }

      return this.sizeCounts.S
        + this.sizeCounts.M
        + this.sizeCounts.L
        + this.sizeCounts.XL;
    },
    filteredLocations() {
      if (!this.filter) {
        return this.locations;
      }

      return this.locations.filter((l) => {
        const title = l.organization ? l.organization.title.toLowerCase() : '';

        return `${title} ${l.title.toLowerCase()}`.includes(this.filter.toLowerCase());
      });
    },
  },
  beforeMount() {
    const isSuperadmin = this.$store.state.myAccount.type === 'admin';
    if (isSuperadmin) {
      this.viewOnly = false;
      this.loadLocations();
    } else {
      this.loadUser();
      this.loadLocations();
      this.loadAnalytics();
    }
  },
  methods: {
    customSorter(items, sortBy, sortDesc) {
      if (sortBy[0] === 'title') {
        if (sortDesc[0] === true) {
          return this
            .filteredLocations
            .sort((firstLocation, secondLocation) => {
              if (firstLocation.title.toLowerCase() > secondLocation.title.toLowerCase()) {
                return -1;
              }

              if (firstLocation.title.toLowerCase() < secondLocation.title.toLowerCase()) {
                return 1;
              }

              return 0;
            });
        }

        return this
          .filteredLocations
          .sort((firstLocation, secondLocation) => {
            if (firstLocation.title.toLowerCase() > secondLocation.title.toLowerCase()) {
              return 1;
            }

            if (firstLocation.title.toLowerCase() < secondLocation.title.toLowerCase()) {
              return -1;
            }

            return 0;
          });
      }

      if (sortBy[0] === 'organization.title') {
        if (sortDesc[0] === true) {
          return this
            .filteredLocations
            .sort((firstLocation, secondLocation) => {
              if (
                firstLocation.organization.title.toLowerCase()
                  > secondLocation.organization.title.toLowerCase()
              ) {
                return -1;
              }

              if (
                firstLocation.organization.title.toLowerCase()
                  < secondLocation.organization.title.toLowerCase()
              ) {
                return 1;
              }

              return 0;
            });
        }

        return this
          .filteredLocations
          .sort((firstLocation, secondLocation) => {
            if (
              firstLocation.organization.title.toLowerCase()
                > secondLocation.organization.title.toLowerCase()
            ) {
              return 1;
            }

            if (
              firstLocation.organization.title.toLowerCase()
                < secondLocation.organization.title.toLowerCase()
            ) {
              return -1;
            }

            return 0;
          });
      }

      return this.filteredLocations;
    },
    getWolkCounts(locationId) {
      const wolkCounts = this.wolkCounts[locationId];

      if (wolkCounts) {
        return wolkCounts;
      }

      return {
        S: 0,
        M: 0,
        L: 0,
        XL: 0,
      };
    },
    getTotalWolkCount(locationId) {
      let totalCount = 0;

      const wolkCounts = this.wolkCounts[locationId];
      if (!wolkCounts) {
        return totalCount;
      }

      Object.values(wolkCounts).forEach((wc) => {
        totalCount += wc;
      });

      return totalCount;
    },
    loadLocations() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/locations`;

      const params = {};
      if (this.$store.getters['auth/isUser']) {
        params.includeWolkCounts = 'true';
      }

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        params,
      })
        .then((response) => {
          const {
            locations,
            organizations,
          } = response.data;

          if (organizations) {
            this.organizationsLoaded = true;

            this.locations = locations.map((l) => ({
              ...l,
              organization: organizations.find((o) => o._id === l.organizationId),
            }));

            if (response.data.wolkCounts) {
              this.wolkCounts = response.data.wolkCounts;
            }

            return;
          }

          this.locations = locations;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    deleteLocation(location) {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/locations/${location._id}`;

      axios({
        method: 'delete',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          console.log(response.data);
        })
        .catch((error) => {
          if (error && error.response && [422, 409].includes(error.response.status)) {
            this.$store.commit('app/snackbarText', {
              text: 'Kan de locatie niet verwijderen, contoleer of er nog wolks of gebruikers gekoppeld zijn.',
              visible: true,
            });
          }
        })
        .finally(() => {
          this.loading = false;

          this.loadLocations();
        });
    },
    loadAnalytics() {
      this.loading = true;
      const url = `${config.VUE_APP_API_BASE_URL}/analytics`;

      axios({
        method: 'get',
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
      })
        .then((response) => {
          if (response.data && response.data.sizeCounts) {
            this.sizeCounts = response.data.sizeCounts;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadUser() {
      const url = `${config.VUE_APP_API_BASE_URL}/users/${this.$store.state.auth.id}`;
      axios({
        url,
        headers: {
          Authorization: `Bearer ${this.$store.state.auth.jwt}`,
        },
        method: 'get',
      }).then((response) => {
        const { user } = response.data;
        if (user) {
          this.viewOnly = user.isViewOnly;
        }
      });
    },
  },
};
</script>
