var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',[_c('v-col',[_c('v-breadcrumbs',{staticClass:"pa-0",attrs:{"items":_vm.breadcrumbs,"large":""}})],1)],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Locaties "),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Zoeken","single-line":"","hide-details":""},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}}),_c('v-spacer'),(_vm.canAddAndDeleteLocation)?_c('v-btn',{attrs:{"to":{ name: 'locationsNew' },"color":"primary"}},[_vm._v(" Voeg toe ")]):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredLocations,"items-per-page":20,"footer-props":{ itemsPerPageOptions: [10, 20, 50, 200] },"loading":_vm.loading,"must-sort":"","sort-by":_vm.tableSortBy,"sort-desc":_vm.tableSortDesc,"custom-sort":_vm.customSorter},on:{"update:sortBy":function($event){_vm.tableSortBy=$event},"update:sort-by":function($event){_vm.tableSortBy=$event},"update:sortDesc":function($event){_vm.tableSortDesc=$event},"update:sort-desc":function($event){_vm.tableSortDesc=$event}},scopedSlots:_vm._u([{key:"item.organization.title",fn:function(ref){
var item = ref.item;
return [(item.organization)?_c('router-link',{attrs:{"to":{ name: 'organizationsShow', params: { id: item.organization._id }}}},[_vm._v(" "+_vm._s(item.organization.title)+" ")]):_vm._e()]}},{key:"item.title",fn:function(ref){
var item = ref.item;
return [(item.title)?_c('router-link',{attrs:{"to":{ name: 'locationsShow', params: { id: item._id }}}},[_vm._v(" "+_vm._s(item.title)+" ")]):_vm._e()]}},{key:"item.wolkCounts",fn:function(ref){
var item = ref.item;
return [_vm._l((_vm.getWolkCounts(item._id)),function(value,key){return _c('span',{key:key},[_c('b',[_vm._v(_vm._s(key))]),_vm._v(": "+_vm._s(value)+" "),_c('span',{staticClass:"mx-2"},[_vm._v(" | ")])])}),_c('span',[_c('b',[_vm._v("Totaal")]),_vm._v(": "+_vm._s(_vm.getTotalWolkCount(item._id))+" ")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.canAddAndDeleteLocation)?_c('wolk-delete-button',{attrs:{"question":"Are you sure?"},on:{"confirmed":function($event){return _vm.deleteLocation(item)}}}):_vm._e()]}},(_vm.showWolkCountFooters)?{key:"body.append",fn:function(ref){
var tableHeaders = ref.headers;
return [_c('tr',[_c('td'),_c('td',{attrs:{"colspan":"1"}},[_vm._v(" Geen ")]),_c('td',{attrs:{"colspan":tableHeaders.length - 2}},[_vm._l((_vm.unassignedCounts),function(value,key){return _c('span',{key:key},[_c('b',[_vm._v(_vm._s(key))]),_vm._v(": "+_vm._s(value)+" "),_c('span',{staticClass:"mx-2"},[_vm._v(" | ")])])}),_c('span',[_c('b',[_vm._v("Totaal")]),_vm._v(": "+_vm._s(_vm.unassignedTotalCount)+" ")])],2)]),_c('tr',[_c('td'),_c('td',[_vm._v(" Totaal ")]),_c('td',{attrs:{"colspan":tableHeaders.length - 2}},[_vm._l((_vm.sizeCounts),function(value,key){return _c('span',{key:key},[_c('b',[_vm._v(_vm._s(key))]),_vm._v(": "+_vm._s(value)+" "),_c('span',{staticClass:"mx-2"},[_vm._v(" | ")])])}),_c('span',[_c('b',[_vm._v("Totaal")]),_vm._v(": "+_vm._s(_vm.totalAllSizedCount)+" ")])],2)])]}}:null],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }